// uP10
import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import { useNavigate } from 'react-router-dom'
import path from 'src/routers/Path'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/stores'
import Button from '@mui/material/Button'
import { clearLocalStorage, handleNavigateApp, isMobileDevice } from 'src/utils'
import RenderIf from 'src/components/RenderIf'
import { apiLogout } from 'src/services/AuthService'
import { LOCAL_STORAGE_KEYS, RESPONSE_STATUS } from 'src/constants/enum'
import { useEffect, useState } from 'react'
import { clearToken } from 'src/stores/Token'

const MyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const paymentInfoStorage = localStorage.getItem(
    LOCAL_STORAGE_KEYS.PAYMENT_INFO
  )
  const [paymentInfo, setPaymentInfo] = useState(
    paymentInfoStorage ? JSON.parse(paymentInfoStorage) : null
  )

  const token = useSelector((state: RootState) => state.token)
  const profile = useSelector((state: RootState) => state.profile)
  const fullName = profile?.name_sei
    ? `${profile.name_sei} ${profile.name_mei}`
    : ''
  const menu = [
    {
      id: 1,
      title: textJP.my_page.convenient,
      subMenu: [
        {
          id: 2,
          title: textJP.my_page.my_ticket,
          onClick: () =>
            // go to uT10 (App)
            handleNavigateApp({ pathMobile: path.transportation_ticket_list })
        }
      ]
    },
    {
      id: 3,
      title: textJP.my_page.support,
      subMenu: [
        {
          id: 4,
          title: textJP.my_page.how_to_use,
          onClick: () =>
            handleNavigateApp({
              pathMobile: path.construction,
              pathBrowser: path.construction,
              navigate
            })
        },
        {
          id: 5,
          title: textJP.my_page.faq,
          onClick: () =>
            handleNavigateApp({
              pathMobile: path.faq,
              pathBrowser: path.faq,
              navigate
            })
        }
      ]
    },
    {
      id: 6,
      title: textJP.my_page.about_app,
      subMenu: [
        {
          id: 7,
          title: textJP.my_page.term_of_use,
          onClick: () =>
            handleNavigateApp({
              pathMobile: path.term_of_use,
              pathBrowser: path.term_of_use,
              navigate
            })
        },
        {
          id: 8,
          title: textJP.my_page.privacy_policy,
          onClick: () =>
            handleNavigateApp({
              pathMobile: path.privacy_policy,
              pathBrowser: path.privacy_policy,
              navigate
            })
        },
        {
          id: 9,
          title: textJP.my_page.special_comerial,
          onClick: () =>
            handleNavigateApp({
              pathMobile: path.commercial_transactions,
              pathBrowser: path.commercial_transactions,
              navigate
            })
        },
        {
          id: 10,
          title: textJP.my_page.company_info,
          onClick: () => window.open('https://oaksway.com', '_blank')
        },
        {
          id: 11,
          title: textJP.my_page.contact,
          onClick: () =>
            handleNavigateApp({
              pathMobile: path.create_contact,
              pathBrowser: path.create_contact,
              navigate
            })
        }
      ]
    }
  ]
  const [appVersion, setAppVersion] = useState('')

  useEffect(() => {
    // Check for app version when the component first loads
    const storedAppInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_INFO)
    if (storedAppInfo) {
      const data = JSON.parse(storedAppInfo)
      if (data?.version) {
        setAppVersion(data.version)
      }
    }

    // Set up event listener for changes to localStorage (from other windows/tabs)
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_KEYS.APP_INFO && event.newValue) {
        const data = JSON.parse(event.newValue)
        if (data?.version) {
          setAppVersion(data.version)
        }
      }

      if (event.key === LOCAL_STORAGE_KEYS.PAYMENT_INFO && event.newValue) {
        const newInfo = JSON.parse(event.newValue)
        setPaymentInfo(newInfo)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [setAppVersion])

  const logout = async () => {
    let deviceId = ''
    const storedAppInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_INFO)
    if (storedAppInfo) {
      const data = JSON.parse(storedAppInfo)
      if (data?.deviceId) {
        deviceId = data?.deviceId
      }
    }

    const response = await apiLogout({ device_id: deviceId })
    if (response?.status === RESPONSE_STATUS.SUCCESS) {
      await clearLocalStorage()

      await dispatch(clearToken());

      await handleNavigateApp({
        pathMobile: path.logout,
        pathBrowser: path.home,
        navigate
      })
    }
  }

  return (
    <LayoutMain
      title={textJP.my_page.title}
      containerClassName='bl_flex flex-col flex-1'
    >
      <>
        {token ? (
          <div className='ut_bg_lightBlue ut_mt20 ut_mb20'>
            <div className='ly_container'>
              <h2 className='el_ttl_username truncate'>
                {fullName ? fullName : textJP.my_page.member_user}
              </h2>
              <button
                type='button'
                onClick={() =>
                  handleNavigateApp({
                    pathMobile: path.profile,
                    pathBrowser: path.profile,
                    navigate
                  })
                }
                className='bl_radiusBox ut_bg_white bl_flex justify_between ut_mb10 bl_tab'
              >
                <div className='el_txt_flex'>
                  <i className='el_icm_user el_txt_xlg el_txt_gray'></i>
                  {textJP.my_page.profile}
                </div>
              </button>
              <button
                type='button'
                onClick={() =>
                  handleNavigateApp({
                    pathMobile: path.vehicle_create,
                    pathBrowser: path.vehicle_create,
                    navigate
                  })
                }
                className='bl_radiusBox ut_bg_white bl_flex justify_between ut_mb10 bl_tab'
              >
                <div className='el_txt_flex'>
                  <i className='el_icm_car el_txt_xlg el_txt_gray'></i>
                  {textJP.vehicle_info.title}
                </div>
              </button>
              <button
                type='button'
                onClick={() =>
                  handleNavigateApp({
                    pathMobile: paymentInfo
                      ? path.payment_infor_detail
                      : path.payment_infor_update,
                    pathBrowser: paymentInfo
                      ? path.payment_infor_detail
                      : path.payment_infor_update,
                    navigate
                  })
                }
                className='bl_radiusBox ut_bg_white bl_flex justify_between ut_mb10 bl_tab'
              >
                <div className='el_txt_flex'>
                  <i className='el_icm_credit el_txt_xlg el_txt_gray'></i>
                  {textJP.my_page.credit_card}
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div className='ly_container'>
            <p className='el_txt_bold500 el_txt_s el_txt_center'>
              {textJP.my_page.please_login}
            </p>
            <div className='el_txt_center ut_mt20'>
              <Button
                type='button'
                variant='contained'
                onClick={() =>
                  handleNavigateApp({
                    pathMobile: path.login_app,
                    pathBrowser: path.login,
                    navigate
                  })
                }
                className='el_btn_common'
              >
                {textJP.btn.login_register}
              </Button>
            </div>
          </div>
        )}

        {menu.map((item: any) => {
          return (
            <div key={item.id} className='menu-item'>
              <div className='ut_bg_gray'>
                <h3 className='el_ttl_mypage'>{item.title}</h3>
              </div>
              <div className='ly_container'>
                {item.subMenu.map((sub: any) => {
                  return (
                    <button
                      type='button'
                      key={sub.id}
                      onClick={(event: any) =>
                        sub?.onClick && sub.onClick(event)
                      }
                      className='bl_flex justify_between align_center ut_mb20 bl_tab'
                    >
                      <p className='text-13px'>{sub.title}</p>
                      <i className='el_icm_arrowR el_txt_gray'></i>
                    </button>
                  )
                })}
              </div>
            </div>
          )
        })}

        <div className='ly_footer'>
          <div className='bl_form'>
            <RenderIf isTrue={!!token}>
              <div className='bl_flex justify_center'>
                <button type='button' onClick={logout} className='el_txt_link'>
                  {textJP.btn.logout}
                </button>
              </div>
            </RenderIf>
            <RenderIf isTrue={isMobileDevice()}>
              <p className='el_txt_gray el_txt_s ut_mt5'>{appVersion}</p>
            </RenderIf>
          </div>
        </div>
      </>
    </LayoutMain>
  )
}

export default MyPage
