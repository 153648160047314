import InfoDialog from 'src/components/dialog/InfoDialog'
import listCredit from 'src/assets/images/list-credit.png'
import { useState } from 'react'

const PaymentVerifyCompleted = () => {
  const [open, setOpen] = useState<boolean>(true)

  return (
    <>
      <InfoDialog
        open={open}
        setOpen={setOpen}
        title='支払いの認証'
        description='こちらで認証が完了しました。アプリに戻ってお支払いを続けてください。'
        dialogWrapperClass='pb-25px'
        descriptionClass='el_txt_center el_txt_md'
        preventClose={true}
      >
        <div
          style={{ userSelect: 'none' }}
          className='bl_flex justify_center ut_mt20 ut_mb10'
        >
          <img src={listCredit} alt='' className='h-auto' />
        </div>
      </InfoDialog>
    </>
  )
}

export default PaymentVerifyCompleted
