const path = {
  home: '/', // uP10
  home_app: '/home', // uH10 (App)
  login: '/login', // uL10 web
  login_app: '/login-app', // uL10 (App)
  register: '/register', // uL30
  register_verify: '/register/verify', // uL31
  register_completed: '/register/completed', // uL32
  forgot_password: '/forgot-password', // uL20
  reset_password: '/reset-password', // uL21
  reset_password_completed: '/reset-password/completed', // uL22
  profile: '/profile', // uP20
  change_password: '/profile/change-password', // uP22
  change_email: '/profile/change-email', // uP21
  change_email_verify: '/profile/change-email/verify', // uL31
  vehicle_create: '/vehicle-information', // uP40
  create_contact: '/contact/add', // uA20
  withdraw_verify: '/withdraw/verify', // uP24
  withdraw_completed: '/withdraw/completed', // uP25
  term_of_use: '/term-of-use', // uA12
  privacy_policy: '/privacy-policy', // uA13
  commercial_transactions: '/commercial-transactions', // uA14
  construction: '/construction', // uA15
  faq: '/faq', // uA11
  notification_list: '/notification', // uN10
  notification_detail: (id?: number) =>
    id ? `/notification/${id}` : '/notification/:id', // uN11
  notification_individual_detail: (id?: number) =>
    id ? `/notification/individual/${id}` : `/notification/individual/:id`, // uN11
  invoice_detail: (id?: number) => (id ? `/invoice/${id}` : `/invoice/:id`), // uC12
  payment_infor_update: '/payment-information/update', // uP30
  payment_infor_detail: '/payment-information/detail', // uP30
  transportation_ticket_list: '/transportation-ticket', // uT10 (App)
  close_web: '/close-web', // trigger close webView
  logout: '/logout', // trigger logout
  my_page: '/my-page',
  payment_done: '/payment-done',
  payment_verify_completed: '/payment_verify_completed',
  add_completed: '/add-completed'
}

export default path
