import Dialog from '@mui/material/Dialog'
import { DialogProps } from 'src/models'
import Button from '@mui/material/Button'
import textJP from 'src/langs/ja'
import cx from 'classnames'
import RenderIf from 'src/components/RenderIf'

const InfoDialog = (props: DialogProps) => {
  const {
    open,
    onClose,
    setOpen,
    title,
    description,
    closeLabel,
    closeClassName,
    children,
    preventClose,
    dialogWrapperClass,
    descriptionClass
  } = props

  const handleClose = () => {
    if (!preventClose) {
      setOpen?.(false)
      onClose?.()
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: 'rounded-10px max-w-500px bl_tab mx-4' }}
    >
      <div className={cx('bl_dialog_textWrapper', dialogWrapperClass)}>
        <div className='el_txt_center'>
          <p
            className='el_txt_lg el_txt_bold700'
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
        </div>
        {children}
        <RenderIf isTrue={!!description}>
          <p
            className={cx(
              'el_txt_rg el_txt_paragraph17 ut_mt10',
              descriptionClass
            )}
          >
            {description}
          </p>
        </RenderIf>
      </div>
      <RenderIf isTrue={!preventClose}>
        <div className='bl_dialog_btnWrapper'>
          <Button
            type='button'
            onClick={handleClose}
            className={cx('bl_dialog_btn', closeClassName)}
          >
            {closeLabel || textJP.btn.close}
          </Button>
        </div>
      </RenderIf>
    </Dialog>
  )
}

export default InfoDialog
