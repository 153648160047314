import Dialog from '@mui/material/Dialog'
import { DialogProps } from 'src/models'
import Button from '@mui/material/Button'
import cx from 'classnames'
import textJP from 'src/langs/ja'
import RenderIf from 'src/components/RenderIf'

const ConfirmDialog = (props: DialogProps) => {
  const {
    open,
    onClose,
    setOpen,
    title,
    description,
    onConfirm,
    cancelClassName,
    confirmClassName,
    cancelLabel,
    confirmLabel
  } = props

  const handleClose = () => {
    setOpen?.(false)
    onClose?.()
  }

  const handleConfirm = () => {
    setOpen?.(false)
    onConfirm?.()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: 'rounded-10px max-w-500px bl_tab mx-4' }}
    >
      <div className='bl_dialog_textWrapper'>
        <div className='el_txt_center'>
          <p
            className='el_txt_lg el_txt_bold700'
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
        </div>
        <RenderIf isTrue={!!description}>
          <p className='el_txt_rg el_txt_paragraph17 ut_mt10'>{description}</p>
        </RenderIf>
      </div>
      <div className='bl_dialog_btnWrapper'>
        <Button
          onClick={handleClose}
          type='button'
          className={cx(
            'bl_dialog_btn bl_dialog_btn__2Choices',
            cancelClassName
          )}
        >
          {cancelLabel || textJP.btn.no}
        </Button>
        <Button
          onClick={handleConfirm}
          type='button'
          className={cx(
            'bl_dialog_btn bl_dialog_btn__2Choices',
            confirmClassName
          )}
        >
          {confirmLabel || textJP.btn.yes}
        </Button>
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
