const textJP = {
  common: {
    login_page: 'ログイン',
    email: 'メールアドレス',
    current_email: '現在のメールアドレス',
    email_temp: '新しいメールアドレス',
    password: 'パスワード',
    place_name: '地名',
    name: 'お名前',
    content: 'お問い合わせ内容',
    full_name: '山田　太郎',
    classification_number: '分類番号',
    car_number_hiragana: 'ひらがな',
    serial_number: '一連指定番号',
    authentication_code_hash: '認証コード',
    new_password: '新しいパスワード',
    application_name: 'PLAMOBI',
    resend_code: '認証コードを再送する',
    password_notice: '半角英字と数字を含む8文字以上',
    password_notice_1: '※半角英字と数字を含む8文字以上で設定してください。',
    name_mei_kana: 'フリガナ',
    name_sei_kana: 'フリガナ',
    name_mei: 'お名前',
    name_sei: 'お名前',
    postal_code: '郵便番号',
    prefecture_code: '都道府県',
    address1: '市区町村・番地',
    address2: '建物・ビル名',
    phone_number: '連絡先番号',
    notice_required: '＊必須',
    please_select: '選択',
    please_verify: '認証コードを入力してください',
    WRONG_INFORMATION: 'メールアドレスまたはパスワードが間違っています。',
    INCORRECT_CODE: '認証コードが利用できません。',
    CODE_EXPIRED:
      '認証コードの有効期限が切れました。<br />認証コードを再送してください。',
    incorrect_card_data: 'カード情報に誤りがあります。',
    invalid_expiry_year: '有効期限年に誤りがあります。',
    expired_card: '有効期限切れのカードです。',
    invalid_card_name: '不正なカードホルダー名',
    COMMON_EXCEPTION: 'サーバーエラーが発生しました。',
    EMAIL_UNAUTHENTICATED:
      'メールがまだ認証されていません。再度登録してください。',
    EMAIL_EXISTED: 'メールは既に存在します。',
    EMAIL_NOT_FOUND: 'メー​​ルが見つかりませんでした。',
    EMAIL_AUTHENTICATED: 'メールは認証されています。',
    REGISTER_SUCCESSFULLY: '正常に登録されました。',
    INCORRECT_CURRENT_EMAIL: '現在のメールが正しくありません。',
    SAME_AS_CURRENT_EMAIL:
      '新しいメールは現在のメールと同じにすることはできません。',
    NEW_EMAIL_EXISTED: '新しいメールが既に存在します。',
    CODE_NOT_FOUND: '認証コードが利用できません。',
    resend_code_message: '認証コードを再送しました',
    post_code_not_found: '郵便番号が無効です。',
    invalid_user: 'アカウントが見つかりませんでした。もう一度試してください。',
    ACCOUNT_ALREADY_DELETED:
      'アカウントが無効になっているため、ログインできません。',
    PAYJP_NOT_AUTHENTICATED: '認証に失敗したため、カード情報を更新できません。',
    error: {
      system_error_title: 'エラーが発生しました',
      system_error_mess:
        '恐れ入りますが、<br/> もう一度やり直してください。<br/> 繰り返しエラーが発生する場合は、<br/> 運営元までお問合せください。',
      system_error_mess_1:
        '恐れ入りますが、もう一度やり直してください。繰り返しエラーが発生する場合は、運営元までお問合せください。'
    },
    payment_confirm:
      '下のボタンをクリックして確認画面にリダイレクトし、お支払い情報を確認してください。'
  },
  login: {
    toggle_password: 'パスワードを表示する',
    to_forgot_password: 'パスワードを忘れた方',
    to_register: '新規登録はこちら'
  },
  register: {
    title: 'アカウント登録',
    notice:
      'メールアドレスとパスワードを登録してください。気になったエリアをブックマークできます。さらに会員情報を登録するとチケットをオンライン決済できます。',
    email_notice_1: '確認できるメールアドレスを登録してください。',
    email_notice_2: '※あとから変更することができます。',
    password_notice_1: '半角英字と数字を含む8文字以上で設定してください。',
    password_notice_2: '※あとから変更することができます。',
    accept_condition_2: '同意します',
    to_login: 'アカウントをお持ちの方はこちら'
  },
  register_verify: {
    notice:
      '登録したメールアドレスにメールを送信しました。メールに記載された認証コードを入力してアカウント登録を完了してください。'
  },
  register_completed: {
    title: 'アカウントを登録しました',
    message_1:
      'ご利用ありがとうございます。<br/> アカウントを登録しました。ログイン画面よりログインしてご利用ください。'
  },
  forgot_password: {
    title: 'パスワード再設定',
    message_1:
      'メールアドレスを入力して送信してください。パスワード再設定に必要な認証コードを送信します。'
  },
  reset_password: {
    title: '認証コードを入力し、<br/> 新しいパスワードを設定してください',
    title_format: '認証コードを入力し、新しいパスワードを設定してください',
    message_1:
      '登録したメールアドレスにメールを送信しました。メールに記載された認証コードを入力し、新しいパスワードを設定してください。'
  },
  reset_password_completed: {
    title: '新しいパスワードを <br/> 設定しました',
    title_format: '新しいパスワードを 設定しました',
    message_1:
      '新しいパスワードを登録しました。ログイン画面よりアカウント情報を入力してログインしてください。'
  },
  change_password: {
    title: 'パスワード変更'
  },
  change_email: {
    title: 'メールアドレス変更',
    notice: '※ 新しいメールアドレスに認証コードが送信されます。'
  },
  vehicle_info: {
    title: '車の情報',
    notice:
      'パークアンドライドプランで駐車場をご利用の場合、ご利用する車の情報を登録してください。',
    delete_message: '車の情報を削除する',
    modal_title:
      '登録されている <br/> 車の情報を削除します。<br /> よろしいですか？',
    place_name_placeholder: '横浜',
    place_name_des: '漢字で入力してください',
    classification_number_des: '半角英数字で入力してください',
    serial_number_des: '半角数字で入力してください'
  },
  contact: {
    contact_sent: 'お問い合わせを送信しました。',
    message:
      'ご利用いただきありがとうございます。 <br /> お問い合わせを送信しました。',
    step_1: '入力',
    step_2: '確認',
    step_3: '完了'
  },
  my_page: {
    title: 'マイページ',
    please_login: 'アカウントをお持ちでない方、ログインしていない方',
    member_user: 'Member User',
    profile: 'プロフィール設定',
    credit_card: 'クレジットカード情報',
    convenient: '便利',
    my_ticket: '交通チケット',
    support: 'サポート',
    how_to_use: 'ご利用ガイド',
    faq: 'よくある質問',
    about_app: 'アプリについて',
    term_of_use: '利用規約',
    privacy_policy: 'プライバシーポリシー',
    special_comerial: '特定商取引法に基づく表記',
    company_info: '運営会社',
    contact: 'お問い合わせ'
  },
  profile: {
    title: 'プロフィール',
    user_info: 'ユーザー情報',
    full_name: 'お名前',
    sei: '姓',
    mei: '名',
    full_name_kana: 'フリガナ',
    last_name_kana: 'セイ',
    first_name_kana: 'メイ',
    postal_code_des: 'ハイフンなしで入力してください',
    phone_description: 'ハイフンなしで入力してください',
    account_info: 'アカウント情報',
    withdraw: '退会申請',
    dialog: {
      no_withdraw_title: '退会できません',
      no_withdraw_des:
        '利用予定のチケットがあります。 チケットのキャンセルを行ってから退会申請を行ってください。',
      confirm_withdraw_title: '退会申請を行いますか？',
      confirm_withdraw_des:
        '退会するには退会申請を行い、届いたメールから退会手続きを行う必要があります。'
    }
  },
  faq: {
    title: 'よくある質問'
  },
  withdraw: {
    notice:
      '登録メールアドレスにメールを送信しました。メールに記載された認証コードを入力して退会手続きを完了してください。',
    message_1: '＜退会に関する注意事項＞',
    message_2: '・退会するとログインできなくなり、利用履歴が',
    message_3: '閲覧できなくなります',
    message_4:
      '・取得済のクーポンがある場合、そのクーポンは破棄され利用できなくなります。'
  },
  withdraw_completed: {
    title: 'アカウント削除完了',
    message_1: 'アカウントを削除しました。',
    message_2: 'ご利用ありがとうございました。',
    message_3: 'またのご利用をお待ちしております。',
    message_4:
      '再度ご登録いただく場合は、新しくアカウント登録を行ってください。'
  },
  notification: {
    title: 'お知らせ',
    my_self: 'あなた宛て',
    news: 'ニュース',
    important: '重要なお知らせ',
    no_my_self_mess: 'あなた宛てのお知らせはまだありません。',
    no_news: 'お知らせはありません。'
  },
  payment_info: {
    title: 'クレジットカード情報',
    cvc: 'セキュリティコード',
    cvc_notice: 'カード裏面の3桁もしくは4桁の番号を入力してください',
    card_number: 'カード番号',
    card_number_notice: '半角数字で入力してください',
    expiry_date: '有効期限',
    card_name: 'カード名義',
    card_name_placeholder: 'TARO　YAMADA',
    delete_message: 'クレジットカード情報を削除する',
    confirm_title:
      '登録されている <br /> クレジットカードを削除します。<br/> よろしいですか？',
    cannot_delete_title: '削除できません',
    cannot_delete_description:
      '利用予定のチケットがあるため、クレジットカード情報を削除できません。',
    cannot_update_title: '更新できません',
    cannot_update_description:
      'すでにチケットを使用する予定があるため、クレジット カード情報を更新できません。'
  },
  invoice_detail: {
    title: '領収書',
    notice: '但：{field}として',
    message_1: '上記正に領収いたしました'
  },
  btn: {
    login: 'ログイン',
    register: '登録する',
    send: '送信する',
    save: '保存する',
    change: '変更',
    back: '戻る',
    delete: '削除する',
    no: 'いいえ',
    yes: 'はい',
    close: '閉じる',
    check_contact: '内容を確認する',
    login_register: 'ログイン / 新規登録',
    update: '更新する',
    ok: 'OK',
    reload: '再読み込み',
    logout: 'ログアウト'
  },
  validation_message: {
    required: '{field}は必須です',
    wrong_format: '正しい形式で入力してください。'
  }
}

export default textJP
